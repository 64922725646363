// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-female-body-tsx": () => import("/Users/andriivandakurov/work/revat/revat-landing/src/pages/female-body.tsx" /* webpackChunkName: "component---src-pages-female-body-tsx" */),
  "component---src-pages-index-tsx": () => import("/Users/andriivandakurov/work/revat/revat-landing/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-oferta-tsx": () => import("/Users/andriivandakurov/work/revat/revat-landing/src/pages/oferta.tsx" /* webpackChunkName: "component---src-pages-oferta-tsx" */),
  "component---src-pages-polityka-konfidentsijnosti-tsx": () => import("/Users/andriivandakurov/work/revat/revat-landing/src/pages/polityka-konfidentsijnosti.tsx" /* webpackChunkName: "component---src-pages-polityka-konfidentsijnosti-tsx" */),
  "component---src-pages-thanks-tsx": () => import("/Users/andriivandakurov/work/revat/revat-landing/src/pages/thanks.tsx" /* webpackChunkName: "component---src-pages-thanks-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/andriivandakurov/work/revat/revat-landing/.cache/data.json")

